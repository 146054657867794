<template>
  <section>
    <v-row no-gutters>
      <v-col cols="12">
        <HeroAlt :hero-alt="heroAlt" />
        <v-container class="py-16">
          <p class="text-h6 text-uppercase font-weight-light text-center my-16">
            Tratamos de hacer la diferencia a través de la preparación continua y el
            respeto por nuestra filosofía basada en la conexión humana.
          </p>
          <h2 class="text-h4 text-md-h3 text-center font-weight-black text-capitalize">
            Formación
          </h2>
          <br />
          <br />
          <v-row>
            <v-col
              v-for="(card, index) in cards1"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              xl="2"
              class="text-center"
            >
              <v-avatar size="80" class="mb-5" color="primary">
                <v-icon dark large>
                  {{ card.icon }}
                </v-icon>
              </v-avatar>
              <div class="title text-uppercase mt-1 mb-4" v-text="card.title"></div>
              <p v-text="card.text"></p>
              <v-row no-gutters>
                <v-col cols="12"> </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br />
          <br />
          <h2 class="text-h4 text-md-h3 text-center font-weight-black text-capitalize">
            Seminarios
          </h2>
          <br />
          <br />
          <v-row>
            <v-col
              v-for="(card, index) in cards2"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              xl="2"
              class="text-center"
            >
              <v-avatar size="80" class="mb-5" color="primary">
                <v-icon dark large>
                  {{ card.icon }}
                </v-icon>
              </v-avatar>
              <div class="title text-uppercase mt-1 mb-4" v-text="card.title"></div>
              <p v-text="card.text"></p>
              <v-row no-gutters>
                <v-col cols="12"> </v-col>
              </v-row>
            </v-col>
          </v-row>
          <br />
          <br />
          <h2 class="text-h4 text-md-h3 text-center font-weight-black text-capitalize">
            ISO, Seguridad y Tecnología
          </h2>
          <br />
          <br />
          <v-row>
            <v-col
              v-for="(card, index) in cards3"
              :key="index"
              cols="12"
              sm="6"
              md="4"
              xl="2"
              class="text-center"
            >
              <v-avatar size="80" class="mb-5" color="primary">
                <v-icon dark large>
                  {{ card.icon }}
                </v-icon>
              </v-avatar>
              <div class="title text-uppercase mt-1 mb-4" v-text="card.title"></div>
              <p v-text="card.text"></p>
              <v-row no-gutters>
                <v-col cols="12"> </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </section>
</template>

<script>
import HeroAlt from "@/components/sections/HeroAlt";
export default {
  components: {
    HeroAlt,
  },
 mounted() {
    window.scrollTo(0, 0);
  },

  data() {
    return {
      heroAlt: [
        {
          src: "pexels-ekaterina-bolovtsova-4048767.jpg",
          heading: " Capacitación ",
        },
      ],
      cards1: [
        {
          title: "Formación Integral en RH",
          icon: "mdi-account-star",
        },
        {
          title: "Administración estratégica",
          icon: "mdi-chart-arc",
        },
        {
          title: "Scrum Product Owner",
          icon: "mdi-debug-step-over",
        },
      ],
      cards2: [
        {
          title: "Seminario “Mentes Maestras con PNL y Mindfulness”",
          icon: "mdi-puzzle",
        },
        {
          title: "Seminario de habilidades y competencias blandas",
          icon: "mdi-shape-plus",
        },
        {
          title: "Seminario en CMFT coaching, mentoring, formador de formadores",
          icon: "mdi-tie",
        },
      ],
      cards3: [
        {
          title: "Auditoria de RH bajo ISO 19011",
          icon: "mdi-checkbox-marked-circle-outline",
        },
        {
          title:
            "Gestor en Seguridad y salud en el trabajo bajo las normas ISO 45001 e ISO 19011",
          icon: "mdi-heart",
        },
        {
          title: "Fundamentos en ISO 9001:2015, ISO 1400:29015 ISO 45001:2018",
          icon: "mdi-traffic-light",
        },
        {
          title:
            "Gestor de evaluación de personas en entornos laborales y organizacionales bajo ISO 10667-1 e ISO 10667-2",
          icon: "mdi-tag-faces",
        },
        {
          title: "Gestor en tecnologías e Industria 4.0",
          icon: "mdi-factory",
        },
        {
          title: "Anexo SSPA",
          icon: "mdi-fire",
        },
      ],
    };
  },
  head() {
    return {
      title: "Soluciones",
      meta: [
        {
          hid: "description",
          name: "description",
          content:
            "Infographic hypotheses influencer user experience Long madel ture gen-z paradigm shift client partner network product seilans solve management influencer analytics leverage virality. incubator seed round massmarket. buyer agile development growth hacking business-to-consumer ecosystem",
        },
      ],
    };
  },
};
</script>
